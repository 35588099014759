import React, { useContext, useEffect } from 'react';
import { asRem, Breakpoint } from 'lib/css';
import { useNavigate, Link } from 'react-router-dom';
import { stripKeys } from 'lib/utils';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { ReactComponent as DropdownIcon } from 'assets/icons/icon-dropdown.svg';
import { UserContext } from 'core/UserContext';
import { fetchSportsQuery } from 'core/query';
import checkIcon from 'assets/icons/icon-check-white.svg';
import { SportIcon } from 'sports/SelectSport';
import { StatusError, StatusLoading } from 'core/Status';
import { AvailableSports } from 'modal';
import { ReactComponent as AddIcon } from 'assets/icons/icon-add.svg';
import { Text14Regular, Label12Regular } from 'style/typography';
import * as SportsDropdownMenu from '@radix-ui/react-dropdown-menu';
import { AccountFeaturesGetQuery } from 'account/query';

const SportsDropdownWrapper = styled.div`
  position: relative;
  svg {
    fill: currentColor;
  }
  .rz-box {
    padding: ${asRem(10)} ${asRem(20)};
    font-size: var(--label-lg-fontSize);
    line-height: var(--label-lg-lineHeight);
  }
  .sports-wrapper {
    padding: ${asRem(11)} ${asRem(16)};
    border-radius: var(--layout-border-radius);
    background: var(--color-border-1);
    margin-left: ${asRem(16)};
    cursor: pointer;
    display: flex;
    gap: ${asRem(8)};
    align-items: center;

    .app-name {
    }

    .content-wrapper {
      display: flex;
      align-items: center;

      .label-12-regular {
        display: none;
        @media screen and (min-width: ${Breakpoint.sm}) {
          display: flex;
        }
      }
    }

    .svg-wrapper {
      display: flex;
      width: ${asRem(20)};
      height: ${asRem(20)};
      border-radius: ${asRem(8)};
      margin-right: ${asRem(10)};
      &.active {
        &.cricket-icon {
          background: #F8964C;
        }
        &.football-icon {
          background: #22A447;
        }
        &.other-icon {
          background: #22A447;
        }
        &.kabaddi-icon {
          background: #DC4A87;
        }
        &.other-icon {
          background: #52C0E7;
        }
        &.circleji-icon {
          background: #E7A710;
        }
        &.fantasyeditor-icon {
          background: #FF7C7C;
        }
      }
      &>svg {
        display: inline-block;
        vertical-align: top;
        fill: currentColor;
        text-align: center;
        margin: ${asRem(6)};
        width: ${asRem(8)};
        height: ${asRem(8)};
      }
      @media screen and (min-width: ${Breakpoint.sm}) {
        display: none;
      }
    }

    
    /* &>span {
      color: var(--color-text-1);
      display: inline-block;
      vertical-align: top;
      margin-right: ${asRem(6)};
    }
    &>.dropdown {
      display: inline-block;
      vertical-align: top;
      margin-top: ${asRem(6)};
      margin-left: ${asRem(6)};
    } */
  }
  

`;

const SportsDropdownMenuTrigger = styled(SportsDropdownMenu.Trigger)`
  outline: none;
  padding: ${asRem(0)};
  color: var(--color-text-1);
`;
const SportsDropdownMenuContent = styled(SportsDropdownMenu.Content)`
  margin-top: ${asRem(20)};
  background: var(--color-border-1);
  padding: ${asRem(28)};
  border-radius: ${asRem(8)};
  width: ${asRem(280)};
  border: 1px solid var(--color-border-2);
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
`;
const SportsDropdownMenuItem = styled(SportsDropdownMenu.Item)` 
  cursor: pointer;
  margin-bottom: ${asRem(16)};
  padding-bottom: ${asRem(16)};
  border-bottom: ${asRem(1)} solid var(--color-border-2);
  outline: none;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
  }

  a {
    padding: 0;
    color: var(--color-text-1);
  }

  .svg-wrapper {
    display: inline-block;
    vertical-align: top;
    width: ${asRem(23)};
    height: ${asRem(24)};
    border-radius: ${asRem(8)};
    margin-right: ${asRem(10)};
    &.active {
      &.cricket-icon {
        background: #F8964C;
      }
      &.football-icon {
        background: #22A447;
      }
      &.other-icon {
        background: #22A447;
      }
      &.kabaddi-icon {
        background: #DC4A87;
      }
      &.other-icon {
        background: #52C0E7;
      }
      &.circleji-icon {
        background: #E7A710;
      }
      &.fantasyeditor-icon {
        background: #FF7C7C;
      }
    }
    &>svg {
      display: inline-block;
      vertical-align: top;
      fill: currentColor;
      text-align: center;
      margin: ${asRem(6)};
      width: ${asRem(11)};
      height: ${asRem(11)};
    }
  }

  .check {
    float: right;
    padding-top: ${asRem(5)};
    margin-right: 0;
  }
`;

export function SportsDropdown({ user }) {
  const { loading, data, error } = useQuery(fetchSportsQuery);
  const userContext = useContext(UserContext);

  let allSports = [];
  if (data) {
    allSports = data && data.sports_sports && data.sports_sports.all_sports;
  }

  const {
    data: accountData,
    loading: accountLoading,
    error: accountError,
  } = useQuery(AccountFeaturesGetQuery, {
    variables: {
      account: {
        _hashkey: userContext.accountKey,
      },
    },
    skip: !userContext.accountKey,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (accountData?.ants_acc_sports_account_features_get) {
      const accFeatures = stripKeys(
        accountData?.ants_acc_sports_account_features_get?.item?.account_features,
      );
      userContext.setAccountFeatures({
        ...accFeatures,
      });
    }
  }, [accountData]);

  const onSportSelect = (sport) => {
    userContext.setSport(sport);
    navigate('/');
  };

  return (
    <SportsDropdownWrapper>
      {(loading || accountLoading) && (<StatusLoading message="Hold on..." />)}
      {(error || accountError) && (<StatusError error={error || accountError} />)}
      {allSports && allSports.length >= 1 && (
        <SportsDropdownMenu.Root modal={false}>
          {user.sport && (
          <>
            <SportsDropdownMenuTrigger>
              <div
                className="sports-wrapper"
              >
                <div className="content-wrapper">
                  <Label12Regular as="span" className="app-name">
                    {AvailableSports[user.sport].name}
                  </Label12Regular>
                  <div className={`svg-wrapper ${userContext.sport.toLocaleLowerCase()}-icon 
                    ${userContext.sport === AvailableSports[user.sport]?.key ? 'active' : ''}`}
                  >
                    <SportIcon sport={user.sport} />
                  </div>
                </div>
                <DropdownIcon />
              </div>
            </SportsDropdownMenuTrigger>
            <SportsDropdownMenuContent>
              {allSports.map((sport) => (
                <SportsDropdownMenuItem key={sport} onClick={() => onSportSelect(sport)}>
                  <div className={`svg-wrapper ${userContext.sport.toLocaleLowerCase()}-icon 
                    ${userContext.sport === AvailableSports[sport]?.key ? 'active' : ''}`}
                  >
                    <SportIcon sport={sport} />
                  </div>
                  <Text14Regular as="span">{AvailableSports[sport]?.name ?? sport}</Text14Regular>
                  {userContext.sport === sport && (
                    <img src={checkIcon} alt="check icon" className="check" />
                  )}
                </SportsDropdownMenuItem>
              ))}
              <SportsDropdownMenuItem>
                <Link to="/pricing">
                  <div className="svg-wrapper">
                    <AddIcon />
                  </div>
                  <Text14Regular as="span">Add a sport</Text14Regular>
                </Link>
              </SportsDropdownMenuItem>
            </SportsDropdownMenuContent>

          </>
          )}
        </SportsDropdownMenu.Root>
      )}
    </SportsDropdownWrapper>
  );
}

SportsDropdown.propTypes = {
  user: PropTypes.object,
};
